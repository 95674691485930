import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { DatePicker, Input } from 'antd';
import RadioButton from '../../../../../../../components/RadioButton/RadioButton';
import dayjs from 'dayjs';

interface FormFieldsProps {
  questions: string[];
  martialStatus: { label: string; value: string }[];
  values: any;
  setFieldValue: any;
  errors: any;
}

const FormFields: React.FC<FormFieldsProps> = ({ questions, martialStatus, values, setFieldValue, errors }) => {
  return (
    <div className="w-full">
      {questions.map((elm: any, index) => (
        <div className="flex mt-8 w-full form-item" key={index}>
          <div className="deprovision-form w-full">
            <label className="text-[#1F3C66] text-[20px] font-rubik font-medium ">
              {`${index + 1}. ${elm?.question}`}
            </label>

            <Field
              className="outline-none w-full bg-[#F5F5F5] px-[10px] rounded-md pt-1 mt-4"
              as="textarea"
              rows={3}
              style={{ resize: 'none' }}
              placeholder="Enter Comment"
              name={`appraiserComments[${index}]`}
            />
            <ErrorMessage
              name={`appraiserComments[${index}]`}
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
        </div>
      ))}

      <div className="flex flex-col lg:flex-row w-full mt-8 lg:mt-16 __date">
        <div className="w-full lg:w-1/2 flex flex-col">
          <label className="text-[#595959] text-[18px] font-medium" htmlFor="">
            Interview Conducted By:
          </label>
          <Field
            as={Input}
            type="text"
            className="bg-[#F5F5F5] rounded-md border-0 w-full lg:w-1/2 mt-4"
            name="interviewBy"
            disabled
          />
          <ErrorMessage
            name="interviewBy"
            component="div"
            className="text-red-500 text-sm mt-1"
          />
        </div>
        <div className="w-full lg:w-1/2 flex lg:justify-end pt-3 lg:pt-0">
          <div className="w-full lg:w-1/2 flex flex-col justify-end">
            <label className="text-[#595959] text-[18px] font-medium" htmlFor="">
              Date
            </label>
            <DatePicker
              disabled
              className="bg-[#F5F5F5] rounded-md border-0 w-[50%] lg:w-[100%] mt-4"
              placeholder="DD/MM/YYYY"
              name="date"
              defaultValue={dayjs(values.date, "YYYY-MM-DD")}
              value={values.date}
              onChange={(date) => setFieldValue('date', date)}
            />
            <ErrorMessage
              name="date"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-7 lg:pt-16 __radio">
        <label
          className="text-[#595959] text-[18px] font-medium mb-3"
          htmlFor=""
        >
          Type of Exit:
        </label>
        <Field
          name="typeOfExit"
          render={({ field }: { field: { name: string; value: string; onChange: (e: React.ChangeEvent<any>) => void; onBlur: (e: React.FocusEvent<any>) => void } }) => (
            <RadioButton
              {...field}
              options={martialStatus}
              onChange={(newValue: string) => setFieldValue('typeOfExit', newValue)}
            />
          )}
        />
        {/* ErrorMessage for Type of Exit */}
        <ErrorMessage
          name="typeOfExit"
          component="div"
          className="text-red-500 text-sm mt-1"
        />
      </div>
      {values.typeOfExit === "Others" && (<div className=" pt-10 flex flex-col ">
        <label className="text-[#595959] text-[18px] font-medium" htmlFor="reason">
          Reason for Relieving:
        </label>
        <Field
          as={Input}
          type="text"
          className="bg-[#F5F5F5] rounded-md border-0 mt-4 overflow-hidden text-ellipsis whitespace-nowrap w-full lg:w-1/3"
          name="exitReason"
          disabled
        />
        <ErrorMessage
          name="exitReason"
          component="div"
          className="text-red-500 text-sm mt-1"
        />
      </div>)}

    </div>

  );
};

export default FormFields;
